import React from 'react'
import { useNavigate } from 'react-router-dom'

export const BackButton = () => {
    const navigate = useNavigate()
    return (
        <div className='flex rounded-full absolute -top-2 -left-2 z-20 bg-gray-400 hover:bg-gray-500 dark:bg-gray-600 hover:dark:bg-gray-700 w-7 justify-center'>
            <button onClick={() => navigate('..')} className='text-white dark:text-gray-300 hover:dark:text-gray-200 rounded-md' title='Cerrar ventana'>
                <i className='fa-solid fa-arrow-left text-lg bg-transparent dark:bg-transparent px-1 py-0 rounded-t-md' />
            </button>
        </div>
    )
}
