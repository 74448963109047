import React, { useEffect } from 'react'

import { useGeneralParams } from '../../../../hooks/useDataPaginate'
import { MinimalActionButton } from '../../../../components/form/minimalActionButton'
import { useSelector } from 'react-redux'
import { DefaultParamsForRecall } from '../../../../utils/defaulStates'
import { Callers } from '../../../../hooks/Callers'
// import { Actions } from '../../../../components/form/actions'
// import { ModalForm } from '../../../../components/modals/ModalForm'
// import { CreateVendedorValues } from '../../../../FormSchemes/VendedorScheme'


export const ListVendedoresTable = ({ globalParams }) => {
    const {
        dispatch,
        data, setData,
        paginate, setPaginate,
        selectedDay, setSelectedDay,
        selecteds, setSelecteds,
        selectAllChecked, setSelectAllChecked,
        isChecked, setIsChecked,
        stateData, setStateData,
        requestAuthPaginate,
        TableContainer,
        Paginator,
        Searcher,
        formatFilters,
        FilterSelect,
        // RangeDate,
        TableSection,
        ActionSection,
        Section,
        redirect
    } = useGeneralParams()
    const reloadMainTable = useSelector(state => state.login.reloadMainTable)

    const getDataPaginate = async () => {
        await requestAuthPaginate({
            method: 'get',
            url: `/users_vend/pag`,
            data: null,
            queries: paginate,
            setData: setData,
            setStateData: setStateData,
            setQueries: setPaginate,
            dispatch: dispatch,
            aditionalParams: { filterBy: 'nombres', rolname: globalParams?.rolname }
        })
    }
    useEffect(() => {
        getDataPaginate();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), paginate.estado, reloadMainTable]);

    useEffect(() => {
        // console.log(selecteds)
    }, [selecteds]);

    useEffect(() => {
        setSelectAllChecked(false)
        setIsChecked(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), paginate.estado, reloadMainTable]);

    const reloadTable = () => {
        Callers(dispatch)
        setSelectAllChecked(false)
        setIsChecked(false)
        setSelecteds([])
    }
    return (
        <Section>
            <ActionSection>
                {/* <Actions
                    buttons={[
                        {
                            icon: 'add',
                            label: 'Asignar clientes',
                            action: () => setCrearVendedorModal(true)
                        },
                    ]}
                /> */}
                <Searcher
                    paginate={paginate}
                    setPaginate={setPaginate}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    width='6/12'
                    options={[
                        {
                            label: 'Nombre',
                            value: 'nombres'
                        },
                        {
                            label: 'Código',
                            value: 'codigo'
                        }
                    ]}
                    reloadTable={reloadTable}
                />
                <div className='flex gap-2 flex-wrap'>
                    {/* <RangeDate
                        selectedDay={selectedDay}
                        setSelectedDay={setSelectedDay}
                        paginate={paginate}
                        setPaginate={setPaginate}
                    /> */}
                    <FilterSelect
                        paginate={paginate}
                        setPaginate={setPaginate}
                        formatFilter={formatFilters}
                        name='estado'
                        options={[
                            {
                                label: 'Estado',
                                value: ''
                            }
                        ]}
                    />
                    {/* <FilterSelect
                        paginate={paginate}
                        setPaginate={setPaginate}
                        formatFilter={formatFilters}
                        name='estado'
                        options={[
                            {
                                label: 'Departamento',
                                value: ''
                            }
                        ]}
                    /> */}
                    <MinimalActionButton
                        icon='repeat'
                        label=''
                        action={reloadTable}
                    />
                </div>
            </ActionSection>
            <TableSection
                stateData={stateData}
                paginator={Paginator}
                data={data}
            >
                <TableContainer
                    headers={[
                        {
                            label: 'Código',
                            columns: ['codigo']
                        },
                        {
                            label: 'Nombre',
                            columns: ['nombres:apellido_paterno:apellido_materno'],
                            icon: 'fa-solid fa-user-circle text-[30px] text-gray-500',
                            className: 'flex items-center',
                            tag: true,
                            iconRef: 'nombres'
                        },
                        {
                            label: 'Estado',
                            columns: ['estado'],
                            tag: true
                        },
                        // {
                        //     label: 'Roles',
                        //     type: 'tagList',
                        //     tagList: 'rols',
                        //     tagLabel: 'nombre'
                        // },
                        {
                            label: 'Acciones',
                            actions: [
                                {
                                    type: 'cyan',
                                    icon: 'fa-share-from-square',
                                    action: (data) => {
                                        redirect(`users_vend/${data.id}/clientes`)
                                    },
                                    reference: 'id',
                                    redirect: true
                                },
                            ],
                        },
                    ]}
                    data={data.data}
                    checkList={false}
                    selecteds={selecteds}
                    setSelecteds={setSelecteds}
                    selectAllChecked={selectAllChecked}
                    setSelectAllChecked={setSelectAllChecked}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    stateData={stateData}
                />
            </TableSection>
            {/* {
                crearVendedorModal &&
                <ModalForm
                    setModal={setCrearVendedorModal}
                    label='Crear usuario'
                    dataValues={CreateVendedorValues()}
                    urlApi={'/user'}
                    method={'post'}
                    call={recall}
                    buttonLabel='Registrar'
                />
            } */}
        </Section>
    )
}
