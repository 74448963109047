import React from 'react'
import { Routes } from 'react-router-dom'
import { Route } from 'react-router-dom'
import { useGeneralParams } from '../../../../hooks/useDataPaginate'
import { TabContainer } from '../../../../components/tab/TabContainer'
import { TabItem } from '../../../../components/tab/TabItem'
import { VerificationPathByString } from '../../../../utils'
import { ErrorPage } from '../../../error/errorPage'
// import { VentasPorVendedorProductos } from './VentasPorVendedorProductos'
// import { useNavigate } from 'react-router-dom'
import { Navigate } from 'react-router-dom'
// import { VentasPorProductos } from './VentasPorProductos'
import { AlmacenPorVendedor } from './AlmacenPorVendedor'
import { AlmacenPorVentaDeOficina } from './AlmacenPorVentaDeOficina'
import { AlmacenEstadoEmpresasMayoristas } from './AlmacenEstadoEmpresasMayoristas'
import { formatDateWithTime } from '../../../../utils/dateFormat'

export const InformeGeneralDeAlmacenTab = () => {
    const {
        Section,
    } = useGeneralParams('')

    // const navigate = useNavigate()
    // useEffect(() => {
    //     navigate('cantidad-almacenes-por-vendedor')
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])

    const DefaultParams = {
        transportistas: {
            headers: [
                {
                    label: 'Fecha de registro',
                    columns: ['createdAt'],
                    transform: true,
                    func: formatDateWithTime
                },
                {
                    label: 'Transportista',
                    columns: ['transportista_nombre:transportista_detalles']
                },
                {
                    label: 'ingreso / egreso',
                    columns: ['ingreso'],
                    booleanState: true,
                    booleanOptions: ['Ingreso', 'Egreso']
                },
                {
                    label: 'Cantidad',
                    columns: ['cantidad'],
                    numberValue: true
                },
                {
                    label: 'Concepto',
                    columns: ['tipo'],
                },
            ],
            aditionalColsRef: ''
        }
    }

    return (
        <Section>
            <TabContainer>
                <TabItem
                    to='cantidad-almacenes-por-vendedor'
                    label='CANTIDAD EN ALMACENES POR VENDEDOR'
                    active={VerificationPathByString('/cantidad-almacenes-por-vendedor')}
                />
                <TabItem
                    to='venta-oficina-con-contenido-cantidad-empresa'
                    label='CANTIDAD DE VENTAS DE OFICINA EMPRESAS'
                    active={VerificationPathByString('/venta-oficina-con-contenido-cantidad-empresa')}
                />
                <TabItem
                    to='venta-oficina-con-contenido-cantidad-mayorista'
                    label='CANTIDAD DE VENTAS DE OFICINA MAYORISTAS'
                    active={VerificationPathByString('/venta-oficina-con-contenido-cantidad-mayorista')}
                />
                {/* <TabItem
                    to='venta-oficina-con-contenido-efectivo'
                    label='EFECTIVO DE VENTAS DE OFICINA EMPRESAS Y MAYORISTAS'
                    active={VerificationPathByString('/venta-oficina-con-contenido-efectivo')}
                /> */}
                <TabItem
                    to='estado-envases-empr-may-empresa'
                    label='CANTIDAD DE ENVASES EMPRESAS'
                    active={VerificationPathByString('/estado-envases-empr-may-empresa')}
                />
                <TabItem
                    to='estado-envases-empr-may-mayorista'
                    label='CANTIDAD DE ENVASES MAYORISTAS'
                    active={VerificationPathByString('/estado-envases-empr-may-mayorista')}
                />
                <TabItem
                    to='detalles-de-envases'
                    label='DETALLES DE ENVASES DE VENDEDORES Y PUNTOS DE VENTA'
                    active={VerificationPathByString('/detalles-de-envases')}
                />
                <TabItem
                    to='estado-ventas'
                    label='ESTADO ACTUAL DE ALMACEN DE VENDEDORES'
                    active={VerificationPathByString('/estado-ventas')}
                />
                <TabItem
                    to='estado-puntos-de-venta'
                    label='ESTADO ACTUAL DE ALMACEN DE PUNTOS DE VENTA'
                    active={VerificationPathByString('/estado-puntos-de-venta')}
                />
                <TabItem
                    to='estado-otros-productos'
                    label='ESTADO ACTUAL DE ALMACEN DE OTROS PRODUCTOS'
                    active={VerificationPathByString('/estado-otros-productos')}
                />
                <TabItem
                    to='transportistas-tcc'
                    label='MOVIMIENTO TRANSPORTISTAS TCC'
                    active={VerificationPathByString('/transportistas-tcc')}
                />
                <TabItem
                    to='transportistas-tv'
                    label='MOVIMIENTO TRANSPORTISTAS TV'
                    active={VerificationPathByString('/transportistas-tv')}
                />
            </TabContainer>
            <Routes>
                <Route
                    index
                    element={<Navigate to='cantidad-almacenes-por-vendedor' />}
                />
                <Route
                    path='cantidad-almacenes-por-vendedor'
                    element={
                        <TabBodyContainer>
                            <AlmacenPorVendedor
                                title='INGRESOS Y EGRESOS CON CONTENIDO Y DE VACIOS'
                                subtitle='Turriles de 200L'
                                urlApi='informe/almacenPorVendedor/lt200/pag'
                                isAmount={true}
                            />
                            <AlmacenPorVendedor
                                title='INGRESOS Y EGRESOS CON CONTENIDO Y DE VACIOS'
                                subtitle='Turriles de 100L'
                                urlApi='informe/almacenPorVendedor/lt100/pag'
                                isAmount={true}
                            />
                        </TabBodyContainer>
                    } />
                <Route
                    path='venta-oficina-con-contenido-cantidad-empresa'
                    element={
                        <TabBodyContainer>
                            <AlmacenPorVentaDeOficina
                                title='CANTIDAD DE VENTA DE OFICINA EMPRESAS'
                                subtitle='Turriles de 200L'
                                urlApi='informe/almacenVentaDeOficina/lt200/pag?tipo=cantidad&rolname=empresa'
                                isAmount={false}
                            />
                            <AlmacenPorVentaDeOficina
                                title='CANTIDAD DE VENTA DE OFICINA EMPRESAS'
                                subtitle='Turriles de 100L'
                                urlApi='informe/almacenVentaDeOficina/lt100/pag?tipo=cantidad&rolname=empresa'
                                isAmount={false}
                            />
                        </TabBodyContainer>
                    } />
                <Route
                    path='venta-oficina-con-contenido-cantidad-mayorista'
                    element={
                        <TabBodyContainer>
                            <AlmacenPorVentaDeOficina
                                title='CANTIDAD DE VENTA DE OFICINA MAYORISTAS'
                                subtitle='Turriles de 200L'
                                urlApi='informe/almacenVentaDeOficina/lt200/pag?tipo=cantidad&rolname=mayorista'
                                isAmount={false}
                            />
                            <AlmacenPorVentaDeOficina
                                title='CANTIDAD DE VENTA DE OFICINA MAYORISTAS'
                                subtitle='Turriles de 100L'
                                urlApi='informe/almacenVentaDeOficina/lt100/pag?tipo=cantidad&rolname=mayorista'
                                isAmount={false}
                            />
                        </TabBodyContainer>
                    } />
                {/* <Route
                    path='venta-oficina-con-contenido-efectivo'
                    element={
                        <TabBodyContainer>
                            <AlmacenPorVentaDeOficina
                                title='EFECTIVO DE VENTA DE OFICINA EMPRESAS Y MAYORISTAS'
                                subtitle='Turriles de 200L'
                                urlApi='informe/almacenVentaDeOficina/lt200/pag?tipo=efectivo_total'
                                isAmount={true}
                            />
                            <AlmacenPorVentaDeOficina
                                title='EFECTIVO DE VENTA DE OFICINA EMPRESAS Y MAYORISTAS'
                                subtitle='Turriles de 100L'
                                urlApi='informe/almacenVentaDeOficina/lt100/pag?tipo=efectivo_total'
                                isAmount={true}
                            />
                        </TabBodyContainer>
                    } /> */}
                <Route
                    path='estado-envases-empr-may-empresa'
                    element={
                        <TabBodyContainer>
                            <AlmacenEstadoEmpresasMayoristas
                                title='CANTIDAD DE ENVASES EMPRESAS'
                                subtitle='Turriles de 200L'
                                urlApi='informe/almacenDetallesEmpMay/lt200/pag?rolname=empresa'
                                isAmount={true}
                            />
                            <AlmacenEstadoEmpresasMayoristas
                                title='CANTIDAD DE ENVASES EMPRESAS'
                                subtitle='Turriles de 100L'
                                urlApi='informe/almacenDetallesEmpMay/lt100/pag?rolname=empresa'
                                isAmount={true}
                            />
                        </TabBodyContainer>
                    } />
                <Route
                    path='estado-envases-empr-may-mayorista'
                    element={
                        <TabBodyContainer>
                            <AlmacenEstadoEmpresasMayoristas
                                title='CANTIDAD DE ENVASES MAYORISTAS'
                                subtitle='Turriles de 200L'
                                urlApi='informe/almacenDetallesEmpMay/lt200/pag?rolname=mayorista'
                                isAmount={true}
                            />
                            <AlmacenEstadoEmpresasMayoristas
                                title='CANTIDAD DE ENVASES MAYORISTAS'
                                subtitle='Turriles de 100L'
                                urlApi='informe/almacenDetallesEmpMay/lt100/pag?rolname=mayorista'
                                isAmount={true}
                            />
                        </TabBodyContainer>
                    } />
                <Route
                    path='detalles-de-envases'
                    element={
                        <TabBodyContainer>
                            <AlmacenEstadoEmpresasMayoristas
                                title='DETALLES DE ENVASES DE VENDEDORES Y PUNTOS DE VENTA'
                                subtitle='Turriles de 200L'
                                urlApi='informe/detallesEnvases/lt200/pag'
                                isAmount={true}
                            />
                            <AlmacenEstadoEmpresasMayoristas
                                title='DETALLES DE ENVASES DE VENDEDORES Y PUNTOS DE VENTA'
                                subtitle='Turriles de 100L'
                                urlApi='informe/detallesEnvases/lt100/pag'
                                isAmount={true}
                            />
                        </TabBodyContainer>
                    } />
                <Route
                    path='estado-ventas'
                    element={
                        <TabBodyContainer>
                            <AlmacenEstadoEmpresasMayoristas
                                title='ESTADO ACTUAL DE ALMACEN'
                                subtitle='Turriles de 200L'
                                urlApi='/informe/EstadoActual/lt200/pag?rolname=ventas'
                                isAmount={true}
                            />
                            <AlmacenEstadoEmpresasMayoristas
                                title='ESTADO ACTUAL DE ALMACEN'
                                subtitle='Turriles de 100L'
                                urlApi='/informe/EstadoActual/lt100/pag?rolname=ventas'
                                isAmount={true}
                            />
                        </TabBodyContainer>
                    } />
                <Route
                    path='estado-puntos-de-venta'
                    element={
                        <TabBodyContainer>
                            <AlmacenEstadoEmpresasMayoristas
                                title='ESTADO ACTUAL DE ALMACEN DE PUNTOS DE VENTA'
                                subtitle='Turriles de 200L'
                                urlApi='/informe/EstadoActual/lt200/pag?rolname=punto-de-venta'
                                isAmount={true}
                            />
                            <AlmacenEstadoEmpresasMayoristas
                                title='ESTADO ACTUAL DE ALMACEN DE PUNTOS DE VENTA'
                                subtitle='Turriles de 100L'
                                urlApi='/informe/EstadoActual/lt100/pag?rolname=punto-de-venta'
                                isAmount={true}
                            />
                        </TabBodyContainer>
                    } />
                <Route
                    path='estado-otros-productos'
                    element={
                        <TabBodyContainer>
                            <AlmacenEstadoEmpresasMayoristas
                                title='ESTADO ACTUAL DE ALMACEN DE OTROS PRODUCTOS'
                                subtitle=''
                                urlApi='/informe/EstadoActualOtros/pag?rolname=all'
                                isAmount={true}
                            />
                        </TabBodyContainer>
                    } />
                <Route
                    path='transportistas-tcc'
                    element={
                        <TabBodyContainer>
                            <AlmacenEstadoEmpresasMayoristas
                                title='MOVIMIENTO TRANSPORTISTAS TCC'
                                subtitle='Turriles de 200L'
                                urlApi='/informe/AlmacenHistorial/AC - 200LT/pag?rolname=transportista&estado_nombre=TCC'
                                isAmount={false}
                                headers={DefaultParams.transportistas.headers}
                                aditionalColsRef={DefaultParams.transportistas.aditionalColsRef}
                            />
                            <AlmacenEstadoEmpresasMayoristas
                                title='MOVIMIENTO TRANSPORTISTAS TCC'
                                subtitle='Turriles de 100L'
                                urlApi='/informe/AlmacenHistorial/AC - 100LT/pag?rolname=transportista&estado_nombre=TCC'
                                isAmount={false}
                                headers={DefaultParams.transportistas.headers}
                                aditionalColsRef={DefaultParams.transportistas.aditionalColsRef}
                            />
                        </TabBodyContainer>
                    } />
                <Route
                    path='transportistas-tv'
                    element={
                        <TabBodyContainer>
                            <AlmacenEstadoEmpresasMayoristas
                                title='MOVIMIENTO TRANSPORTISTAS TCC'
                                subtitle='Turriles de 200L'
                                urlApi='/informe/AlmacenHistorial/AC - 200LT/pag?rolname=transportista&estado_nombre=TV'
                                isAmount={false}
                                headers={DefaultParams.transportistas.headers}
                                aditionalColsRef={DefaultParams.transportistas.aditionalColsRef}
                            />
                            <AlmacenEstadoEmpresasMayoristas
                                title='MOVIMIENTO TRANSPORTISTAS TCC'
                                subtitle='Turriles de 100L'
                                urlApi='/informe/AlmacenHistorial/AC - 100LT/pag?rolname=transportista&estado_nombre=TV'
                                isAmount={false}
                                headers={DefaultParams.transportistas.headers}
                                aditionalColsRef={DefaultParams.transportistas.aditionalColsRef}
                            />
                        </TabBodyContainer>
                    } />
                <Route path='*' element={<ErrorPage />} />
            </Routes>
        </Section>
    )
}

const TabBodyContainer = ({ children }) => {
    return (
        <div className='grid gap-5'>
            {children}
        </div>
    )
}