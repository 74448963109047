import * as Yup from 'yup';


export const CreateValues = () => {
    return {
        initialValues: {
            nombre: '',
            descripcion: '',
            // precio: 0,
            retornable: false,
            capacidad: '',
        },
        fieldsValidation: Yup.object().shape({
            nombre: Yup.string()
                .min(4, 'Mínimo 4 caracteres!')
                .max(50, 'Máximo 40 caracteres!')
                .required('Campo requerido'),
        }),
        fields: [
            {
                label: 'Nombre',
                name: 'nombre',
                type: 'text',
                required: true,
                placeholder: 'Nombre...',
                autoFocus: true
            },
            {
                label: 'Descripción',
                name: 'descripcion',
                type: 'text',
                required: false,
                placeholder: 'Descripción...',
            },
            // {
            //     label: 'Precio estandar',
            //     name: 'precio',
            //     type: 'groupnumber',
            //     subData: 'Bs.',
            //     required: false,
            // },
            {
                label: 'Capacidad',
                name: 'capacidad',
                type: 'text',
                required: false,
                placeholder: 'Capacidad...',
            },
            {
                label: '¿Envase Retornable?',
                name: 'retornable',
                type: 'checkbox',
            },
        ]
    }
}

export const UpdateValues = (data) => {
    let aditionalUrlApi = data.retornable ? 'retornable' : 'no-retornable'
    let prodTipos = []
    data?.Producto_estados?.forEach(estado => {
        prodTipos.push(estado.tipo + '')
    });
    return {
        initialValues: {
            id: data?.id || '',
            nombre: data?.nombre || '',
            descripcion: data?.descripcion || '',
            precio: data?.precio || 0,
            retornable: data?.retornable || false,
            capacidad: data?.capacidad || '',
            tipos: prodTipos,
        },
        fieldsValidation: Yup.object().shape({
            nombre: Yup.string()
                .min(4, 'Mínimo 4 caracteres!')
                .max(50, 'Máximo 40 caracteres!')
                .required('Campo requerido'),
        }),
        fields: [
            {
                label: 'Nombre',
                name: 'nombre',
                type: 'text',
                required: true,
                placeholder: 'Nombre...',
                autoFocus: true
            },
            {
                label: 'Descripción',
                name: 'descripcion',
                type: 'text',
                required: false,
                placeholder: 'Descripción...',
            },
            // {
            //     label: 'Precio estandar',
            //     name: 'precio',
            //     type: 'groupnumber',
            //     subData: 'Bs.',
            //     required: false,
            //     placeholder: 'Nombre...',
            // },
            {
                label: 'Capacidad',
                name: 'capacidad',
                type: 'text',
                required: false,
                placeholder: 'Capacidad...',
            },
            {
                label: '¿Envase Retornable?',
                name: 'retornable',
                type: 'checkbox',
                disabled: true
            },
            ...(
                data.retornable ?
                    [
                        {
                            label: 'Asignar estados para el producto',
                            name: 'tipos',
                            type: 'checkboxes',
                            required: false,
                            urlApi: `/listas/Estados de producto/subListas?tag=${aditionalUrlApi}`,
                            indexValue: 'nombre',
                            indexLabel: 'nombre',
                        }
                        // {
                        //     label: 'Editar precios por envases',
                        //     name: 'tipos',
                        //     type: 'multiInputForm',
                        //     required: false,
                        //     urlApi: `/listas/Estados de producto/subListas?tag=${aditionalUrlApi}`,
                        //     indexLabel: 'etiqueta',
                        //     dataRef: data,
                        //     title: 'Distribución de almacén'
                        // },
                    ]
                    :
                    []
            ),
        ]
    }
}

export const UpdateProdTiposPerProductoValues = (data) => {
    let aditionalUrlApi = data.retornable ? 'retornable' : 'no-retornable'
    let prodTipos = []
    data?.Producto_estados?.forEach(estado => {
        prodTipos.push(estado.tipo + '')
    });
    return {
        initialValues: {
            productoId: data?.id || '',
            prodTipos: data.prodTipos,
        },
        fieldsValidation: Yup.object().shape({
        }),
        fields: [
            {
                name: 'tipos',
                type: 'multiInputForm',
                required: false,
                urlApi: `/listas/Estados de producto/subListas?tag=${aditionalUrlApi}`,
                indexLabel: 'etiqueta',
                dataRef: data,
                title: null
            },
        ]
    }
}

export const DeleteValues = (data) => {
    return {
        initialValues: {
        },
        fieldsValidation: Yup.object().shape({
        }),
        fields: []
    }
}