import React, { useEffect, useState } from 'react'
import { useGeneralParams } from '../../../../hooks/useDataPaginate'
import { AHEditar, CreateValues, CreateValuesTraspasosExterno, CreateValuesTraspasosInternos, DeleteValues, VentaMoverFecha } from '../../../../FormSchemes/AlmacenHistorialScheme'
import { formatDateWithTime } from '../../../../utils/dateFormat'
import { MinimalActionButton } from '../../../../components/form/minimalActionButton'
import { setReloadMainTable } from '../../../../store/slices/auth/authSlice'
import { DefaultParamsForRecall } from '../../../../utils/defaulStates'
import { useSelector } from 'react-redux'
import { Callers } from '../../../../hooks/Callers'
import { ModalFunc } from '../../../../components/modals/ModalFunc'
import { NotaDeEntregaDeProductoValues, NotaDeRecepcionDeVaciosValues } from '../../../../FormSchemes/AlmacenScheme'
import { ModalPdf } from '../../../../components/modals/ModalPdf'
import NotaEntregaProductoPdf from './almacen_historial_general/NotaEntregaProductoPdf'
import NotaRecepcionVaciosPdf from './almacen_historial_general/NotaRecepcionVaciosPdf'

export const AlmacenHistorialData = ({
    toList,
    dataCard,
}) => {
    const {
        dispatch,
        params,
        data, setData,
        paginate, setPaginate,
        selectedDay, setSelectedDay,
        selecteds, setSelecteds,
        selectAllChecked, setSelectAllChecked,
        isChecked, setIsChecked,
        stateData, setStateData,
        createModal, setCreateModal,
        currentData, setCurrentData,
        deleteModal, setDeleteModal,
        // imports
        requestAuthPaginate,
        TableContainer,
        Paginator,
        formatFilters,
        Searcher,
        FilterSelect,
        RangeDate,
        Actions,
        TableSection,
        ActionSection,
        Section,
        ModalForm,
    } = useGeneralParams('tipo')
    // normal
    const [traspasosPersonalModal, setTraspasosPersonalModal] = useState(false);
    const [traspasosModal, setTraspasosModal] = useState(false);
    const [descargasModal, setDescargasModal] = useState(false);
    const [traspasosIngresoModal, setTraspasosIngresoModal] = useState(false);
    const [VaciadoDeProductoModal, setVaciadoDeProductoModal] = useState(false);
    const [traspasosInternosModal, setTraspasosInternosModal] = useState(false);
    const [MoverFechaModal, setMoverFechaModal] = useState(false);
    const [EditObsModal, setEditObsModal] = useState(false);
    const [EditNroBoletaModal, setEditNroBoletaModal] = useState(false);

    // general
    const [NotaEntregaProductoModal, setNotaEntregaProductoModal] = useState(false);
    const [NotaEntregaProductoPdfModal, setNotaEntregaProductoPdfModal] = useState(false);
    const [NotaEntregaProductoFormValuesModal, setNotaEntregaProductoFormValuesModal] = useState(null);

    const [NotaRecepcionVaciosModal, setNotaRecepcionVaciosModal] = useState(false);
    const [NotaRecepcionVaciosPdfModal, setNotaRecepcionVaciosPdfModal] = useState(false);
    const [NotaRecepcionVaciosFormValuesModal, setNotaRecepcionVaciosFormValuesModal] = useState(null);

    const reloadMinorTable = useSelector(state => state.login.reloadMinorTable)
    const urlPag = ({ url, useEf }) => {
        if (toList === 'normal') {
            if (url)
                return `/almacen_producto/${params.almacen_producto_id}/historials/pag`
            if (useEf)
                return params.almacen_producto_id
        }
        if (toList === 'cierre') {
            if (url)
                return `/almacenCierre/${params.almacen_cierre_id}/historial/pag`
            if (useEf)
                return params.almacen_cierre_id
        }
        if (toList === 'general') {
            if (url)
                return `/almacen/${params.almacen_id}/historialGeneral/pag`
            if (useEf)
                return params.almacen_id
        }
    }
    const getDataPaginate = async () => {
        await requestAuthPaginate({
            method: 'get',
            url: urlPag({ url: true }),
            data: null,
            queries: paginate,
            setData: setData,
            setStateData: setStateData,
            setQueries: setPaginate,
            dispatch: dispatch,
            aditionalParams: { filterBy: 'producto', isDetailed: true }
        })
    }
    useEffect(() => {
        getDataPaginate();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), paginate.tipo, urlPag({ useEf: true }), reloadMinorTable]);

    useEffect(() => {
        setSelectAllChecked(false)
        setIsChecked(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...DefaultParamsForRecall(paginate), paginate.tipo, reloadMinorTable]);

    const reloadTable = () => {
        Callers(dispatch)
    }

    return (
        <Section>
            <ActionSection>
                {
                    toList === 'normal' &&
                    <Actions
                        buttons={[
                            {
                                icon: 'minus',
                                label: 'Traspasos a persona',
                                action: () => setTraspasosPersonalModal(true),
                                className: 'dark:bg-red-700 bg-red-500 text-white'
                            },
                            {
                                icon: 'minus',
                                label: 'Traspasos a entidades',
                                action: () => setTraspasosModal(true),
                                className: 'dark:bg-red-700 bg-red-500 text-white'
                            },
                            {
                                icon: 'minus',
                                label: 'Distribución interna de productos',
                                action: () => setTraspasosInternosModal(true),
                                className: 'dark:bg-rose-700 bg-rose-500 text-white'
                            },
                            {
                                icon: 'minus',
                                label: 'Vaciado de productos',
                                action: () => setVaciadoDeProductoModal(true),
                                className: 'dark:bg-rose-700 bg-rose-500 text-white'
                            },
                            {
                                icon: 'plus',
                                label: 'Traspasos de persona',
                                action: () => setDescargasModal(true),
                                className: 'dark:bg-green-700 bg-green-500 text-white'
                            },
                            {
                                icon: 'plus',
                                label: 'Traspasos de entidades',
                                action: () => setTraspasosIngresoModal(true),
                                className: 'dark:bg-green-700 bg-green-500 text-white'
                            },
                            {
                                icon: 'plus',
                                label: 'Ingreso directo',
                                action: () => setCreateModal(true),
                                className: 'dark:bg-green-700 bg-green-500 text-white'
                            },
                            // {
                            //     icon: 'plus-minus',
                            //     label: 'Traspaso interno',
                            //     action: () => setModalTraspaso(true),
                            //     className: 'bg-sky-400 dark:bg-sky-600 text-white'
                            // },
                            // {
                            //     icon: 'plus-minus',
                            //     label: 'Traspaso a otro almacén',
                            //     action: () => setModalTraspasoExterno(true),
                            //     className: 'bg-green-400 dark:bg-green-700 text-white'
                            // },
                        ]}
                    />
                }
                {
                    toList === 'general' &&
                    <Actions
                        buttons={[
                            {
                                icon: 'file-pdf',
                                label: 'Generar Nota de Entrega de Producto',
                                action: () => setNotaEntregaProductoModal(true),
                                // disabled: selecteds.length <= 0 ? true : false
                            },
                            {
                                icon: 'file-pdf',
                                label: 'Generar Nota de Recepción de Envases Vacios',
                                action: () => setNotaRecepcionVaciosModal(true),
                                className: 'dark:bg-rose-700 bg-rose-500 text-white'
                            },
                        ]}
                    />
                }
                <Searcher
                    paginate={paginate}
                    setPaginate={setPaginate}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    options={[
                        // {
                        //     label: 'Nombre',
                        //     value: 'nombre'
                        // },
                        {
                            label: 'Concepto',
                            value: 'tipo'
                        }
                    ]}
                    reloadTable={reloadTable}
                />
                <div className='flex gap-2 flex-wrap'>
                    <RangeDate
                        selectedDay={selectedDay}
                        setSelectedDay={setSelectedDay}
                        paginate={paginate}
                        setPaginate={setPaginate}
                    />
                    <FilterSelect
                        paginate={paginate}
                        setPaginate={setPaginate}
                        formatFilter={formatFilters}
                        name='tipo'
                        options={[
                            {
                                label: 'Todos',
                                value: ''
                            },
                            {
                                label: 'Ingreso',
                                value: 'ingreso'
                            },
                            {
                                label: 'Egreso',
                                value: 'egreso'
                            }
                        ]}
                    />
                    <MinimalActionButton
                        icon='repeat'
                        label=''
                        action={reloadTable}
                    />
                </div>
            </ActionSection>
            <TableSection
                stateData={stateData}
                paginator={Paginator}
                data={data}
            >
                <TableContainer
                    headers={[
                        ...(toList === 'general' ?
                            [
                                {
                                    label: 'Producto',
                                    columns: ['producto_nombre', 'producto_estado']
                                }
                            ]
                            :
                            []
                        ),
                        {
                            label: 'Alm. Orig./Dest.',
                            columns: ['nombreAlmDestino']
                        },
                        {
                            label: 'Transportista',
                            columns: ['transporNombre']
                        },
                        {
                            label: 'Encargado',
                            columns: ['encarNombre']
                        },
                        {
                            label: 'Ingr./Egre.',
                            columns: ['ingreso'],
                            booleanState: true,
                            booleanOptions: ['Ingreso', 'Egreso']
                        },
                        // {
                        //     label: 'Cantidad anterior',
                        //     columns: ['cantidad_anterior'],
                        //     numberValue: true
                        // },
                        {
                            label: 'Cantidad',
                            columns: ['cantidad'],
                            numberValue: true
                        },
                        {
                            label: 'Cant. Post',
                            columns: ['cantidad_actual'],
                            numberValue: true
                        },
                        // {
                        //     label: 'Cantidad en garantía',
                        //     columns: ['cantidad_garantia'],
                        //     numberValue: true
                        // },
                        // {
                        //     label: 'Garantía Bs.',
                        //     columns: ['monto_garantia'],
                        //     numberValue: true
                        // },
                        // {
                        //     label: 'Cantidad deuda',
                        //     columns: ['cantidad_deuda'],
                        //     numberValue: true
                        // },
                        // {
                        //     label: 'Deuda Bs.',
                        //     columns: ['monto_deuda'],
                        //     numberValue: true
                        // },
                        {
                            label: 'Concepto',
                            columns: ['tipo'],
                        },
                        {
                            label: 'Transacción',
                            columns: ['codigo_transaccion'],
                        },
                        // {
                        //     label: 'CONFIRMACIÓN',
                        //     columns: ['confirmado'],
                        //     boolean: true,
                        //     booleanOptions: ['Confirmado', 'Pendiente'],
                        //     booleanIconTrue: 'fa-check',
                        //     booleanIconFalse: 'fa-xmark'
                        // },
                        {
                            label: 'Fecha de registro',
                            columns: ['createdAt'],
                            transform: true,
                            func: formatDateWithTime
                        },
                        {
                            label: 'Observaciones',
                            columns: ['obs'],
                        },
                        {
                            label: 'Nro. Boleta',
                            columns: ['nro_boleta'],
                        },
                        ...(
                            toList === 'normal' ?
                                [{
                                    label: '',
                                    // stickyR: true,
                                    popActions: [
                                        {
                                            subTitle: 'Editar fecha',
                                            icon: 'far fa-calendar-days',
                                            action: (i) => {
                                                setCurrentData(i)
                                                setMoverFechaModal(true)
                                            },
                                        },
                                        {
                                            subTitle: 'Editar observación',
                                            action: (i) => {
                                                setCurrentData(i)
                                                setEditObsModal(true)
                                            },
                                            icon: 'fa fa-align-left',
                                        },
                                        {
                                            subTitle: 'Editar nro. de boleta',
                                            action: (i) => {
                                                setCurrentData(i)
                                                setEditNroBoletaModal(true)
                                            },
                                            icon: 'fa fa-align-left',
                                        },
                                        {
                                            subTitle: 'Eliminar',
                                            action: (i) => {
                                                setCurrentData(i)
                                                setDeleteModal(true)
                                            },
                                            reference: 'id',
                                            adClass: 'hover:text-red-600',
                                            icon: 'fa fa-trash',
                                        },
                                    ],
                                },]
                                :
                                []
                        )
                    ]}
                    data={data.data}
                    checkList={true}
                    selecteds={selecteds}
                    setSelecteds={setSelecteds}
                    selectAllChecked={selectAllChecked}
                    setSelectAllChecked={setSelectAllChecked}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    stateData={stateData}
                />
            </TableSection>
            {
                traspasosPersonalModal &&
                <ModalForm
                    setModal={setTraspasosPersonalModal}
                    label='Traspasos a personal'
                    dataValues={CreateValuesTraspasosExterno(params?.almacen_producto_id, dataCard, true)}
                    urlApi={`/almacenHistorialTraspasos`}
                    method={'post'}
                    call={() => {
                        reloadTable()
                        // dispatch(setReloadSubTable())
                        dispatch(setReloadMainTable())
                    }}
                    buttonLabel='Registrar'
                />
            }
            {
                traspasosModal &&
                <ModalForm
                    setModal={setTraspasosModal}
                    label='Traspasos a entidades'
                    dataValues={CreateValuesTraspasosExterno(params?.almacen_producto_id, dataCard, false, false)}
                    urlApi={`/almacenHistorialTraspasos`}
                    method={'post'}
                    call={() => {
                        reloadTable()
                        // dispatch(setReloadSubTable())
                        dispatch(setReloadMainTable())
                    }}
                    buttonLabel='Registrar'
                />
            }
            {
                descargasModal &&
                <ModalForm
                    setModal={setDescargasModal}
                    label='Traspasos de persona'
                    dataValues={CreateValuesTraspasosExterno(params?.almacen_producto_id, dataCard, true, true)}
                    // urlApi={`/almHistorials/multiTraspaso`}
                    urlApi={`/almacenHistorialDescargas`}
                    method={'post'}
                    call={() => {
                        reloadTable()
                        // dispatch(setReloadSubTable())
                        dispatch(setReloadMainTable())
                    }}
                    buttonLabel='Registrar'
                />
            }
            {
                traspasosIngresoModal &&
                <ModalForm
                    setModal={setTraspasosIngresoModal}
                    label='Traspasos de entidades'
                    dataValues={CreateValuesTraspasosExterno(params?.almacen_producto_id, dataCard, false, true)}
                    urlApi={`/almacenHistorialTraspasos`}
                    method={'post'}
                    call={() => {
                        reloadTable()
                        // dispatch(setReloadSubTable())
                        dispatch(setReloadMainTable())
                    }}
                    buttonLabel='Registrar'
                />
            }
            {
                traspasosInternosModal &&
                <ModalForm
                    setModal={setTraspasosInternosModal}
                    label='Distribución interna de productos'
                    dataValues={CreateValuesTraspasosInternos(params?.almacen_producto_id, dataCard, false, 'Distribución interna de productos')}
                    urlApi={`/almacenHistorialTraspasosInternos`}
                    method={'post'}
                    call={() => {
                        reloadTable()
                        // dispatch(setReloadSubTable())
                        dispatch(setReloadMainTable())
                    }}
                    buttonLabel='Registrar'
                />
            }
            {
                VaciadoDeProductoModal &&
                <ModalForm
                    setModal={setVaciadoDeProductoModal}
                    label='Distribución interna de productos'
                    dataValues={CreateValuesTraspasosInternos(params?.almacen_producto_id, dataCard, false, 'Vaciado de turriles', true)}
                    urlApi={`/almacenHistorialTraspasosInternos`}
                    method={'post'}
                    call={() => {
                        reloadTable()
                        // dispatch(setReloadSubTable())
                        dispatch(setReloadMainTable())
                    }}
                    buttonLabel='Registrar'
                />
            }
            {
                createModal &&
                <ModalForm
                    setModal={setCreateModal}
                    label='Ingreso directo'
                    dataValues={CreateValues(params?.almacen_producto_id)}
                    urlApi={`/almacenHistorial`}
                    method={'post'}
                    call={() => {
                        reloadTable()
                        // dispatch(setReloadSubTable())
                        dispatch(setReloadMainTable())
                    }}
                    buttonLabel='Registrar'
                />
            }
            {
                deleteModal &&
                <ModalForm
                    setModal={setDeleteModal}
                    label='Eliminar movimiento del almacén'
                    dataValues={DeleteValues(currentData)}
                    urlApi={`/almacen_historial/${currentData.id}`}
                    method={'delete'}
                    call={() => {
                        reloadTable()
                        // dispatch(setReloadSubTable())
                        dispatch(setReloadMainTable())
                    }}
                    buttonLabel='Eliminar'
                />
            }
            {
                MoverFechaModal &&
                <ModalForm
                    setModal={setMoverFechaModal}
                    label='Editar fecha de movimiento'
                    dataValues={VentaMoverFecha(currentData)}
                    urlApi={`/almacenHistorial/moverFecha`}
                    method={'put'}
                    call={reloadTable}
                    buttonLabel='Editar fecha'
                />
            }
            {
                EditObsModal &&
                <ModalForm
                    setModal={setEditObsModal}
                    label='Editar observación'
                    dataValues={AHEditar(currentData, [
                        {
                            label: 'Observación',
                            name: 'obs',
                            type: 'textArea',
                            required: true,
                            placeholder: 'Obs...',
                            autoFocus: false
                        }
                    ])}
                    urlApi={`/almacenHistorial`}
                    method={'patch'}
                    call={reloadTable}
                    buttonLabel='Editar observación de movimiento'
                />
            }
            {
                EditNroBoletaModal &&
                <ModalForm
                    setModal={setEditNroBoletaModal}
                    label='Editar Número de Boleta'
                    dataValues={AHEditar(currentData, [
                        {
                            label: 'Nro. Boleta',
                            name: 'nro_boleta',
                            type: 'text',
                            required: false,
                            placeholder: '#Nro...',
                            autoFocus: true
                        }
                    ])}
                    urlApi={`/almacenHistorial`}
                    method={'patch'}
                    call={reloadTable}
                    buttonLabel='Editar observación de movimiento'
                />
            }
            {/* general */}
            {
                NotaEntregaProductoModal &&
                <ModalFunc
                    setModal={setNotaEntregaProductoModal}
                    label='Datos para la nota de entrega de producto'
                    dataValues={NotaDeEntregaDeProductoValues(params?.almacen_id)}
                    func={(vals) => {
                        setNotaEntregaProductoFormValuesModal(vals)
                        setNotaEntregaProductoPdfModal(true)
                    }}
                    call={reloadTable}
                    buttonLabel='Registrar'
                />
            }
            {
                NotaEntregaProductoPdfModal &&
                <ModalPdf
                    orientation=''
                    setModal={setNotaEntregaProductoPdfModal}
                    width={'md:max-w-5xl'}
                >
                    <NotaEntregaProductoPdf
                        orientation=''
                        data={NotaEntregaProductoFormValuesModal}
                        selecteds={selecteds}
                    />
                </ModalPdf>
            }

            {/* vacios */}
            {
                NotaRecepcionVaciosModal &&
                <ModalFunc
                    setModal={setNotaRecepcionVaciosModal}
                    label='Datos para la nota de recepción de envases vacios'
                    dataValues={NotaDeRecepcionDeVaciosValues(params?.almacen_id)}
                    func={(vals) => {
                        setNotaRecepcionVaciosFormValuesModal(vals)
                        setNotaRecepcionVaciosPdfModal(true)
                    }}
                    call={reloadTable}
                    buttonLabel='Registrar'
                />
            }
            {
                NotaRecepcionVaciosPdfModal &&
                <ModalPdf
                    orientation=''
                    setModal={setNotaRecepcionVaciosPdfModal}
                    width={'md:max-w-5xl'}
                >
                    <NotaRecepcionVaciosPdf
                        orientation=''
                        data={NotaRecepcionVaciosFormValuesModal}
                        selecteds={selecteds}
                    />
                </ModalPdf>
            }
        </Section>
    )
}