import React, { useEffect, useState } from 'react'
import { CreateValues, DeleteValues, UpdateProdTiposPerProductoValues, UpdateValues } from '../../../../FormSchemes/ProductoScheme'
import { useDispatch, useSelector } from 'react-redux'
import { requestDefaulAuth } from '../../../../http/httpRequest'
import { UpdateValuesModal } from '../../../../FormSchemes/GeneralFunctions'
import { Section } from '../../../../components/table/Section'
import { ActionSection } from '../../../../components/table/ActionSection'
import { Actions } from '../../../../components/form/actions'
import { ModalForm } from '../../../../components/modals/ModalForm'
import { setReloadMainTable } from '../../../../store/slices/auth/authSlice'
import OilImage from '../../../../assets/oil.png'
import BarrelOil from '../../../../assets/barrelOil.png'

export const ListProductoTable = () => {
    const [data, setData] = useState([])
    const reloadMainTable = useSelector(state => state.login.reloadMainTable)
    const dispatch = useDispatch()
    const [generalStates, setGeneralStates] = useState({
        currentData: null,
        createProdForm: false,
        updateProdForm: false,
        updateProdTiposForm: false,
        deleteProdForm: false,
    })
    const getData = async () => {
        await requestDefaulAuth(
            'get',
            `/productos/all`,
            null,
            setData,
            dispatch
        )
    }
    useEffect(() => {
        getData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadMainTable]);

    return (
        <Section>
            <ActionSection>
                <Actions
                    buttons={[
                        {
                            icon: 'add',
                            label: 'Crear',
                            action: () => setGeneralStates(x => ({ ...x, createProdForm: true }))
                        },
                        {
                            icon: 'repeat',
                            label: 'Actualizar',
                            action: () => dispatch(setReloadMainTable())
                        },
                    ]}
                />
            </ActionSection>
            <div className='grid grid-cols-2 xl:grid-cols-5 gap-2 my-2'>
                {
                    data != null && data?.map((item, index) => (
                        <CardItem
                            key={index}
                            item={item}
                            setCurrentData={(datai) => setGeneralStates(x => ({ ...x, currentData: datai }))}
                            setUpdateModal={(datai) => setGeneralStates(x => ({ ...x, updateProdForm: datai }))}
                            setDeleteModal={(datai) => setGeneralStates(x => ({ ...x, deleteProdForm: datai }))}
                            setProdTiposUpdateModal={(datai) => setGeneralStates(x => ({ ...x, updateProdTiposForm: datai }))}
                        />
                    ))
                }
            </div>
            {
                generalStates.createProdForm &&
                <ModalForm
                    setModal={(datai) => setGeneralStates(x => ({ ...x, createProdForm: datai }))}
                    label='Crear producto'
                    dataValues={CreateValues()}
                    urlApi={'/producto'}
                    method={'post'}
                    call={() => dispatch(setReloadMainTable())}
                    buttonLabel='Registrar'
                />
            }
            {
                generalStates.updateProdForm &&
                <ModalForm
                    setModal={(datai) => setGeneralStates(x => ({ ...x, updateProdForm: datai }))}
                    label='Editar producto'
                    dataValues={UpdateValues(generalStates.currentData)}
                    urlApi={'/producto'}
                    method={'put'}
                    call={() => dispatch(setReloadMainTable())}
                    buttonLabel='Editar'
                />
            }
            {
                generalStates.updateProdTiposForm &&
                <ModalForm
                    setModal={(datai) => setGeneralStates(x => ({ ...x, updateProdTiposForm: datai }))}
                    label='Editar precios por tipos de producto'
                    dataValues={UpdateProdTiposPerProductoValues(generalStates.currentData)}
                    urlApi={`/productos/${generalStates.currentData.id}/editProdTipos`}
                    method={'put'}
                    call={() => dispatch(setReloadMainTable())}
                    buttonLabel='Editar'
                />
            }

            {
                generalStates.deleteProdForm &&
                <ModalForm
                    setModal={(datai) => setGeneralStates(x => ({ ...x, deleteProdForm: datai }))}
                    label='Eliminar producto'
                    dataValues={DeleteValues(generalStates.currentData)}
                    urlApi={`/producto/${generalStates.currentData.id}`}
                    method={'delete'}
                    call={() => dispatch(setReloadMainTable())}
                    buttonLabel='Eliminar'
                />
            }
        </Section>
    )
}

const CardItem = ({ item, setCurrentData, setUpdateModal, setDeleteModal, setProdTiposUpdateModal }) => {
    return (
        <div className='text-gray-800 dark:text-white flex flex-col bg-white dark:bg-gray-800 rounded-t-xl justify-between'>
            <div className='flex flex-col'>
                <div className='flex justify-center p-3'>
                    <img src={item.retornable ? BarrelOil : OilImage} alt="box" className='w-24' />
                </div>
                <div className='flex flex-col p-2'>
                    <span className='font-bold text-center text-lg'>{item.nombre}</span>
                    <span className='font-semibold text-center text-sm'>{item.retornable ? 'Retornable' : 'No retornable'}</span>
                    <span className='text-sm'>{item.descripcion}</span>
                </div>
            </div>
            <div className='flex flex-row p-2 justify-end gap-2'>
                <i className='fa fa-money-bill-1 px-2 py-1 bg-gray-100 dark:bg-gray-900/50 hover:bg-gray-200 hover:dark:bg-gray-700 rounded-md text-lg text-green-500 cursor-pointer' onClick={() => UpdateValuesModal(item, setCurrentData, setProdTiposUpdateModal)} />
                <i className='fa fa-edit px-2 py-1 bg-gray-100 dark:bg-gray-900/50 hover:bg-gray-200 hover:dark:bg-gray-700 rounded-md text-lg text-yellow-500 cursor-pointer' onClick={() => UpdateValuesModal(item, setCurrentData, setUpdateModal)} />
                <i className='fa fa-trash px-2 py-1 bg-gray-100 dark:bg-gray-900/50 hover:bg-gray-200 hover:dark:bg-gray-700 rounded-md text-lg text-red-500 cursor-pointer' onClick={() => UpdateValuesModal(item, setCurrentData, setDeleteModal)} />
            </div>
        </div>
    )
}