import React, { useEffect } from 'react'
import { HistorialDeSesiones } from './historial_de_sesiones'
import { Users } from './users'
import { HistorialDeCambios } from './historial_de_cambios'
import { Roles } from './roles'
import { Productos } from './productos'
import { Almacenes } from './almacenes'
import { useParams } from 'react-router-dom'
import { Estados } from './estados'
import { CuentaBancaria } from './cuenta_bancarias'

import { Cuaderno } from './cuadernos'
import { Deudas } from './deudas'
import { Vendedores } from './vendedores'
import { Clientes } from './clientes'
import { Efectivo } from './efectivos'
import { InformeGeneralDeVentasTab } from './informeGeneralDeVentas/InformeGeneralDeVentasTab'
import { Compras } from './compras'
import { Proveedores } from './proveedores'
import { InformeGeneralDeAlmacenTab } from './informeGeneralDeAlmacen/InformeGeneralDeAlmacenTab'
import { DatosGenerales } from './datosGenerales'
import { IngresoCuaderno } from './ingresoCuadernos'
import { InformeGeneralDeTesoreriaTab } from './informeGeneralDeTesoreria/InformeGeneralDeTesoreriaTab'
import { DeudaEnvases } from './deudaEnvases'
import { useSelector } from 'react-redux'
import { Incetivos } from './incentivos'
import { GastosCuadernos } from './gastosCuadernos'
import { IngresosYEgresos } from './ingresosYegresos'
import { useLocation } from 'react-router-dom'

export const Menu = () => {
    const params = useParams()
    const location = useLocation()
    const menus = useSelector(state => state.login?.menus)
    useEffect(() => {
        if (menus != null && menus.length > 0) {
            document.title = `${menus.find(x => x.nombre === params.menuname) ? (menus.find(x => x.nombre === params.menuname)?.etiqueta + ' - ') : ''}Soya Sari`
        }
        window.localStorage.setItem('location', location.pathname)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.menuname, menus]);
    return (
        <>
            {
                params.menuname === 'usuarios' &&
                <Users />
            }
            {
                params.menuname === 'vendedores' &&
                <Vendedores
                    globalParams={{ rolname: 'ventas' }}
                />
            }
            {
                params.menuname === 'puntos-de-venta' &&
                <Vendedores
                    globalParams={{ rolname: 'punto-de-venta' }}
                />
            }
            {
                params.menuname === 'datos-generales' &&
                <DatosGenerales
                />
            }
            {
                params.menuname === 'oficina' &&
                <Vendedores
                    globalParams={{ rolname: 'oficina' }}
                />
            }
            {
                params.menuname === 'clientes' &&
                <Clientes />
            }
            {
                params.menuname === 'proveedores' &&
                <Proveedores />
            }
            {
                params.menuname === 'roles' &&
                <Roles />
            }
            {
                params.menuname === 'listas' &&
                <Estados />
            }
            {
                params.menuname === 'cuentas-bancarias' &&
                <CuentaBancaria />
            }
            {
                params.menuname === 'productos' &&
                <Productos />
            }
            {
                params.menuname === 'almacenes-generales' &&
                <Almacenes
                    almTag='general'
                />
            }
            {
                params.menuname === 'almacén-vendedores' &&
                <Almacenes
                    // almTag='vendedores'
                    rolname='ventas'
                />
            }
            {
                params.menuname === 'almacén-puntos-de-venta' &&
                <Almacenes
                    // almTag='puntos-de-venta'
                    rolname='punto-de-venta'
                />
            }
            {/* {
                params.menuname === 'almacén-oficina' &&
                <Almacenes
                    tipoAlmacen='oficina'
                />
            } */}
            {
                params.menuname === 'almacén-empresas' &&
                <Almacenes
                    rolname='empresa'
                />
            }
            {
                params.menuname === 'almacén-mayoristas' &&
                <Almacenes
                    rolname='mayorista'
                />
            }
            {
                params.menuname === 'almacén-proveedores' &&
                <Almacenes
                    almTag='proveedor'
                />
            }
            {
                params.menuname === 'informe-general-de-almacén' &&
                <InformeGeneralDeAlmacenTab
                // tipoAlmacen='proveedores'
                />
            }
            {
                params.menuname === 'cuadernos' &&
                <Cuaderno
                    globalParams={{ rolname: 'vendedores' }}
                />
            }
            {
                params.menuname === 'cuadernos-oficina' &&
                <Cuaderno
                    globalParams={{ rolname: 'oficina' }}
                />
            }
            {
                params.menuname === 'deudas' &&
                <Deudas />
            }
            {
                params.menuname === 'garantias' &&
                <DeudaEnvases
                    globalParams={{ tipoDeuda: 'garantía' }}
                />
            }
            {
                params.menuname === 'prestamos' &&
                <DeudaEnvases
                    globalParams={{ tipoDeuda: 'préstamo' }}
                />
            }
            {
                params.menuname === 'informe-general-de-ventas' &&
                <InformeGeneralDeVentasTab />
            }
            {
                params.menuname === 'informe-mensual-de-ventas' &&
                <InformeGeneralDeVentasTab />
            }
            {
                params.menuname === 'informe-anual-de-ventas' &&
                <InformeGeneralDeVentasTab />
            }
            {
                params.menuname === 'informe-general-para-tesoreria' &&
                <InformeGeneralDeTesoreriaTab />
            }
            {
                params.menuname === 'ingreso-de-cuadernos' &&
                <IngresoCuaderno
                    globalParams={{ tipo: 'ingreso-de-cuadernos' }}
                />
            }
            {
                params.menuname === 'caja-central' &&
                <Efectivo
                    globalParams={{ tipo: 'caja-central' }}
                />
            }
            {
                params.menuname === 'bancos-central' &&
                <Efectivo
                    globalParams={{ tipo: 'bancos-central' }}
                />
            }
            {
                params.menuname === 'garantías-en-efectivo-central' &&
                <Efectivo
                    globalParams={{ tipo: 'garantías-en-efectivo-central' }}
                />
            }
            {
                params.menuname === 'garantías-en-bancos-central' &&
                <Efectivo
                    globalParams={{ tipo: 'garantías-en-bancos-central' }}
                />
            }
            {
                params.menuname === 'otras-cuentas' &&
                <Efectivo
                    globalParams={{ tipo: 'otras-cuentas' }}
                />
            }
            {
                params.menuname === 'compras' &&
                <Compras />
            }
            {
                params.menuname === 'historial-de-sesiones' &&
                <HistorialDeSesiones />
            }
            {
                params.menuname === 'historial-de-cambios' &&
                <HistorialDeCambios />
            }
            {
                params.menuname === 'incentivos' &&
                <Incetivos />
            }
            {
                params.menuname === 'gastos-cuadernos' &&
                <GastosCuadernos />
            }
            {
                params.menuname === 'ingresos-y-egresos' &&
                <IngresosYEgresos />
            }

        </>
    )
}
