import React, { useEffect, useRef, useState } from 'react';
import { badgeBooleanStateValue, badgeBooleanStyle, badgeBooleanValue, badgeStaticFormatStyle } from '../../utils/styleFormat';
import Tooltip from '../tooltips/Tooltip';
import { VerificationImageExist } from '../../utils';
import Avatar from '../../assets/profile.png'

const scrollToEnd = () => {
    // setTimeout(() => {
    //     window.scrollTo({
    //         top: (document.documentElement.scrollHeight),
    //         behavior: 'smooth'
    //     })
    // }, 100)
};


const TableCell = ({ head, item, selecteds, setSelecteds, isChecked, setIsChecked, rowIndex, checkList, clickFunc, doubleClickAction }) => {
    const handleCheckboxChange = () => {
        if (setIsChecked)
            setIsChecked(!isChecked);

        if (!isChecked) {
            if (setSelecteds)
                setSelecteds(prevSelecteds => [...prevSelecteds, item]);
        } else {
            if (setSelecteds)
                setSelecteds(prevSelecteds => prevSelecteds.filter(selectedItem => selectedItem !== item));
        }
    };

    const renderCellContent = () => {
        const classParam = [
            'font-semibold',
            // 'font-semibold text-teal-600 dark:text-yellow-500',
            'font-semibold',
            // 'font-semibold text-red-600 dark:text-pink-400',
            'font-semibold',
            // 'font-semibold text-pink-600 dark:text-cyan-400',
            'font-semibold',
        ]
        if (head.icon) {
            return (
                <>
                    {
                        head.iconRef ?
                            <span className='flex bg-stone-300 text-gray-500 dark:bg-gray-700 dark:text-gray-400 uppercase w-7 min-w-7 py-1 font-semibold rounded-full justify-center items-center'>{item[head.iconRef].charAt()}</span>
                            :
                            <i className={`${head?.icon} hidden sm:block`} />
                    }
                    <div className='pl-2'>
                        {
                            head?.columns?.map((h, idw) => (
                                <div
                                    key={idw}
                                    className={classParam[idw]}
                                >
                                    {
                                        h?.split(':')?.map((i) => (
                                            <React.Fragment
                                                key={i}
                                            >
                                                <CellFormat global={head} data={item[i]} />{' '}
                                            </React.Fragment>
                                        ))
                                    }
                                </div>
                            ))
                        }
                    </div>
                </>
            )
        } else if (head.actions) {
            return <div className='flex flex-row gap-2'>
                {head.actions && (
                    <ActionButtonsGroup actions={head.actions} item={item} />
                )}
            </div>
        } else if (head.popActions) {
            return <div className='flex flex-row'>
                {head.popActions && (
                    <PopActionButtonsGroup popActions={head.popActions} item={item} />
                )}
            </div>
        } else if (head.tag) {
            return (
                <div className='flex'>
                    {
                        item[head.columns[0]] ?
                            <div className={`${badgeStaticFormatStyle(item[head.columns[0]])}`}>{item[head.columns[0]]}</div>
                            :
                            item[head.columns[0]] === 0 ?
                                <div className={`${badgeStaticFormatStyle(item[head.columns[0]])}`}>{item[head.columns[0]]}</div>
                                :
                                ''
                    }
                </div>
            )
        } else if (head.numberValue) {
            return (
                <div className='flex justify-end'>
                    <div className={`${head.color ? head.color : 'dark:bg-gray-800 bg-green-100'} dark:text-gray-300 text-gray-700 px-1 font-semibold rounded-sm`}>{item[head.columns[0]]}</div>
                </div>
            )
        } else if (head.type === 'tagList') {
            return (
                <div className='flex flex-wrap gap-1'>
                    {
                        item[head?.tagList]?.map((i, index) => (
                            <span
                                key={index}
                                className={`text-xs px-2 pt-0.5 pb-1 rounded bg-gray-300 dark:bg-gray-800`}>
                                {i[head?.tagLabel]}
                            </span>
                        ))
                    }
                </div>

            )
        } else if (head.type === 'listData') {
            return (
                <ul className='pl-4 font-semibold'>
                    {
                        item[head?.tagList]?.map((i, index) => (
                            <li
                                key={index}
                                className='flex items-center gap-2'
                            // className={`text-xs px-2 pt-0.5 pb-1 rounded bg-gray-300 dark:bg-gray-800`}
                            >
                                <i className={`fa fa-${head?.iconFa ? head?.iconFa : 'circle'} ${head?.aditionalClass ? head?.aditionalClass : 'text-[6px] pt-0.5 text-gray-600 dark:text-gray-300'}`} /> <span>{i[head?.tagLabel]}</span>
                            </li>
                        ))
                    }
                </ul>

            )
        } else if (head.type === 'iconImage') {
            return (
                <div className='text-center'>
                    <img
                        src={head?.image ? require(`../../assets/${VerificationImageExist(item[head?.image])}`) : Avatar}
                        alt='img'
                        style={{
                            width: '45px',
                            height: '45px',
                            alignItems: 'center',
                            textAlign: 'center',
                            verticalAlign: 'middle',
                            margin: 'auto'
                        }} />
                    <span className='text-xs'>{item[head?.image]}</span>
                </div>

            )
        } else if (head.boolean) {
            return <div className={`flex text-xs text-nowrap font-bold px-1.5 py-0.5 text-center rounded ${badgeBooleanStyle(item[head.columns[0]])}`}>{badgeBooleanValue(item[head.columns[0]], head.booleanOptions, head.booleanIconTrue, head.booleanIconFalse)}</div>
        } else if (head.booleanState) {
            return <div className={`flex text-xs text-nowrap font-bold px-1.5 py-0.5 text-center rounded ${badgeBooleanStyle(item[head.columns[0]])}`}>{badgeBooleanStateValue(item[head.columns[0]], head.booleanOptions, head.booleanIconTrue, head.booleanIconFalse)}</div>
        } else {
            return <div>
                {
                    head?.columns?.map((h, idw) => (
                        <div
                            key={idw}
                            className={classParam[idw]}
                        >
                            {
                                h?.split(':')?.map((i) => (
                                    <span
                                        key={i}
                                        className={`${head.transform ? 'italic text-xs' : ''}`}
                                    >
                                        <CellFormat global={head} data={item[i]} />{' '}
                                    </span>
                                ))
                            }
                        </div>
                    ))
                }
            </div>;
        }
    };

    return (
        <td
            className={`${head.stickyL ? 'sticky left-0 px-2 max-w-[150px] overflow-hidden' : head.stickyR ? 'sticky right-0 px-2 max-w-[150px] overflow-hidden' :
                //  ''
                // ? 'sticky px-0 bg-gray-800 max-w-[100px] overflow-hidden transition-all duration-500 ease-in-out'
                'border border-gray-300 dark:border-gray-800 transition-all duration-1000 ease-in-out'
                } ${doubleClickAction ? 'select-none' : ''}`}
            onClick={clickFunc}
        >
            <div className={`px-2 py-1 text-gray-900 dark:text-white ${head?.className} transition-all duration-500 ease-in-out`}>
                {checkList && (
                    <div className='flex items-center'>
                        <input
                            id={`checkbox-table-search-${rowIndex}`}
                            type='checkbox'
                            className='w-5 h-5 text-yellow-600 bg-gray-100 border-gray-300 rounded focus:ring-yellow-500 dark:focus:ring-yellow-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                        />
                        <label htmlFor={`checkbox-table-search-${rowIndex}`} className='sr-only'>checkbox</label>
                    </div>
                )}
                {renderCellContent()}
            </div>
        </td>
    );
};

const CellFormat = ({ global, data }) => {
    return global.transform ? global.func(data) : data
};

const TableRow = ({ headers, item, indexRow, selectAllChecked, checkList, selecteds, setSelecteds, isChecked, setIsChecked, aditionalColsRef, aditionalColsAmount, doubleClickAction }) => {
    // const rowClass = indexRow % 2 === 0 ? 'bg-white dark:bg-[rgba(55,65,81)]' : 'bg-[rgba(232,232,232)] dark:bg-[rgba(40,49,64)]'
    const rowClass = indexRow % 2 === 0 ? 'bg-white dark:bg-gray-900' : 'bg-[rgba(232,232,232)] dark:bg-gray-900'
    // const rowClass = indexRow % 2 === 0 ? 'bg-white dark:bg-gray-900' : 'bg-stone-100 dark:bg-gray-900'
    // const rowClass = indexRow % 2 === 0 ? (selecteds.includes(item) ? 'bg-white dark:bg-green-200' : 'bg-white dark:bg-[rgba(55,65,81)]') : (selecteds.includes(item) ? 'bg-white dark:bg-green-200' : 'bg-neutral-200 dark:bg-[rgba(40,49,64)]')

    useEffect(() => {
        if (setIsChecked)
            setIsChecked(selectAllChecked)

        if (selectAllChecked) {
            if (setSelecteds)
                setSelecteds(prevSelecteds => [...prevSelecteds, item]);
        } else {
            if (setSelecteds)
                setSelecteds(prevSelecteds => prevSelecteds.filter(selectedItem => selectedItem !== item))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectAllChecked]);

    return (
        <tr
            className={`hover:bg-yellow-50 dark:hover:bg-gray-800 ${rowClass} ${doubleClickAction ? 'cursor-pointer' : ''}`}
            onDoubleClickCapture={doubleClickAction && (() => doubleClickAction(item))}
        >
            {
                checkList &&
                <td className={`w-4 px-4 py-2 sticky left-0 bg-gray-300 dark:bg-gray-800`}>
                    <div className='flex items-center'>
                        <input
                            id={`checkbox-table-search-${indexRow}`}
                            type='checkbox'
                            className='w-5 h-5 text-yellow-600 bg-gray-100 border-gray-300 rounded focus:ring-yellow-500 dark:focus:ring-yellow-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-800 dark:border-gray-600'
                            checked={selecteds.includes(item)}
                            onChange={
                                () => {
                                    if (setSelecteds)
                                        setSelecteds(prevSelecteds => prevSelecteds.includes(item) ? prevSelecteds.filter(selectedItem => selectedItem !== item) : [...prevSelecteds, item])
                                }
                            }
                        />
                        <label htmlFor={`checkbox-table-search-${indexRow}`} className='sr-only'>checkbox</label>
                    </div>
                </td>
            }
            {
                headers?.map((head, idy) => (
                    head &&
                    <TableCell
                        key={idy}
                        head={head}
                        item={item}
                        selecteds={selecteds}
                        setSelecteds={setSelecteds}
                        clickFunc={() => setSelecteds && setSelecteds(prevSelecteds => prevSelecteds.includes(item) ? prevSelecteds.filter(selectedItem => selectedItem !== item) : [...prevSelecteds, item])}
                        doubleClickAction={doubleClickAction}
                    />
                ))
            }
            {
                item[aditionalColsRef]?.map((i, idy) => (
                    <td
                        key={idy}
                        className={'border border-gray-400 dark:border-gray-800'}
                    // onClick={clickFunc}
                    >
                        <div className='flex justify-end px-2 py-1'>
                            <div className={`dark:bg-gray-800 bg-yellow-100 dark:text-gray-300 text-gray-700 px-1 font-semibold rounded-sm`}>
                                {
                                    aditionalColsAmount ?
                                        i?.toLocaleString('es-BO', { minimumFractionDigits: 2 })
                                        :
                                        i
                                }
                            </div>
                        </div>
                    </td>
                ))
            }
        </tr >
    );
};


const ActionButton = ({ type, action, data, icon, reference, tooltipText, actParams, disabled }) => {
    const getButtonColor = () => {
        if (type === 'edit' || type === 'yellow') {
            return 'yellow';
        } else if (type === 'delete') {
            return 'red';
        } else if (type === 'view') {
            return 'cyan';
        } else if (type === 'green') {
            return 'green';
        } else if (type === 'red') {
            return 'red';
        }
        else {
            return type
        }
    };

    return (
        <Tooltip
            text={tooltipText}
        >
            <button
                className={`flex h-8 w-8 justify-center items-center dark:text-${disabled ? 'gray' : getButtonColor()}-500 text-${disabled ? 'gray' : getButtonColor()}-500 dark:hover:bg-gray-900 hover:bg-stone-200 rounded-md cursor-pointer`}
                onClick={() => {
                    action(data)
                    actParams?.redirect && scrollToEnd()
                }}
                disabled={disabled}
            >
                {
                    actParams?.newIcon ?
                        <span className={`${actParams?.newIcon?.className}`} style={{ fontSize: '32px' }} >{actParams?.newIcon?.name}</span>
                        :
                        <i className={`fa-solid ${icon} text-lg`} />
                }
            </button>
        </Tooltip >
    );
};

const ActionButtonsGroup = ({ actions, item }) => {
    return (
        <div className='flex flex-row gap-x-2 justify-start'
            onClick={(e) => {
                e?.stopPropagation();
            }}
        >
            {
                actions.map((action, index) => (
                    action !== null &&
                    (
                        <ActionButton
                            key={index}
                            type={action?.type}
                            action={action?.action}
                            data={item}
                            icon={action?.icon}
                            reference={action?.reference ? item[action?.reference] : null}
                            tooltipText={action?.tooltipText}
                            actParams={action}
                            disabled={action.disabled}
                        />
                    )
                ))
            }
        </div>
    );
};
// const getMTClass = (length) => {
//     const marginTopClasses = {
//         // 4: '-mt-[103px]',
//         7: '-mt-32',
//         6: '-mt-32',
//         5: '-mt-32',
//         4: '-mt-32',
//         3: '-mt-20',
//         2: '-mt-14',
//         1: '-mt-2',
//     };
//     return marginTopClasses[length] || '';
// }

const PopActionButtonsGroup = ({ popActions, item }) => {
    const [isOpen, setIsOpen] = useState(false);
    const popoverRef = useRef(null);
    const buttonRef = useRef(null);

    const handleClickOutside = (event) => {
        if (
            popoverRef.current && !popoverRef.current.contains(event.target) &&
            buttonRef.current && !buttonRef.current.contains(event.target)
        ) {
            setIsOpen(false);
        }
    };

    const handleMouseEnter = () => {
        setIsOpen(true);
    };

    const handleMouseLeave = () => {
        setIsOpen(false);
    };

    const handleClick = () => {
        setIsOpen(prevState => !prevState);
    };

    // const mtPerLength = getMTClass(popActions?.length);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div
            className='relative'
            onClick={(e) => e?.stopPropagation()}
        >
            <div
                className='inline-block text-left'
                ref={popoverRef}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <div>
                    <button
                        type='button'
                        ref={buttonRef}
                        className={`inline-flex justify-center rounded-md shadow-md px-3 py-2 bg-gray-600 dark:bg-gray-800 text-xl font-medium text-gray-200 hover:bg-gray-700 hover:dark:bg-gray-900`}
                        onClick={handleClick}
                    >
                        <i className='fa-solid fa-ellipsis-vertical'></i>
                    </button>
                </div>
                {isOpen && (
                    <div
                        // className={`absolute right-full ${mtPerLength} w-64 sm:w-80 p-1 max-h-20 overflow-auto rounded-md shadow-sm shadow-gray-900 bg-white dark:bg-[#1b2430] ring-1 ring-black ring-opacity-5 z-20`}
                        className={`absolute right-full -mt-20 w-64 sm:w-80 p-1 max-h-20 overflow-auto rounded-md shadow-sm shadow-gray-900 bg-white dark:bg-[#1b2430] ring-1 ring-black ring-opacity-5 z-20`}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        {popActions?.map((popAc, index) => (
                            <div
                                key={index}
                                className={`py-0.5 hover:dark:bg-gray-700 hover:bg-gray-200 font-semibold rounded-md px-1 cursor-pointer flex gap-2 ${popAc?.adClass ?? 'text-gray-900 dark:text-gray-200'}`}
                                role='menu'
                                aria-orientation='vertical'
                                aria-labelledby='options-menu'
                                onClick={() => {
                                    setIsOpen(false);
                                    popAc.action(item);
                                }}
                            >
                                <i className={`${popAc.icon ? popAc.icon : 'far fa-circle-dot'} text-sm`} />
                                <span>{popAc.subTitle}</span>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export const TableContainer = ({
    headers,
    data,
    aditionalCols,
    aditionalColsRef,
    aditionalColsAmount,
    checkList,
    selecteds,
    setSelecteds,
    selectAllChecked,
    setSelectAllChecked,
    isChecked,
    setIsChecked,
    stateData,
    doubleClickAction
}) => {
    return (
        <table className='w-full text-sm text-left text-gray-500 dark:text-gray-400'>
            <thead className='text-xs text-gray-100 capitalcase bg-gray-600 dark:bg-gray-900 dark:text-gray-300'>
                <tr>
                    {
                        checkList &&
                        <th scope='col' className={`px-4 py-2 dark:bg-gray-800 bg-gray-600 sticky left-0 border-y border-gray-600 dark:border-gray-800`}>
                            <div className='flex items-center'>
                                <input
                                    id='checkbox-all-search'
                                    type='checkbox'
                                    className='w-5 h-5 text-yellow-600 bg-gray-800 border-gray-300 rounded focus:ring-yellow-500 dark:focus:ring-yellow-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:border-gray-600'
                                    checked={selecteds.length === 0 ? false : selectAllChecked}
                                    // checked={selectAllChecked}
                                    onChange={() => setSelectAllChecked(!selectAllChecked)}
                                />
                                <label htmlFor='checkbox-all-search' className='sr-only'>checkbox</label>
                            </div>
                        </th>
                    }
                    {headers?.map((head, index) => (
                        head &&
                        <th
                            key={index}
                            scope='col'
                            className={`px-2 py-3 border border-gray-600 dark:border-gray-800 bg-gray-600 dark:bg-gray-900 text-gray-200 dark:text-gray-400 ${head.stickyL ? 'sticky left-0 px-2 max-w-[150px] overflow-hidden' : head.stickyR ? 'sticky right-0 px-2 max-w-[150px] overflow-hidden' : ''}`}
                        >
                            {head.label}
                        </th>
                    ))}
                    {
                        aditionalCols?.map((adc, index) => (
                            <th
                                key={index}
                                scope='col'
                                className={`px-2 py-3 border border-gray-600 dark:border-gray-800 bg-gray-600 dark:bg-gray-900 text-right text-gray-200 dark:text-gray-400`}
                            >
                                {adc}
                            </th>
                        ))
                    }
                </tr>
            </thead>
            <tbody>
                {
                    Array.isArray(data) && data?.map((item, idx) => (
                        <TableRow
                            key={idx}
                            headers={headers}
                            item={item}
                            indexRow={idx}
                            selectAllChecked={selectAllChecked}
                            checkList={checkList}
                            selecteds={selecteds}
                            setSelecteds={setSelecteds}
                            isChecked={isChecked}
                            setIsChecked={setIsChecked}
                            rowIndex={idx}
                            aditionalColsRef={aditionalColsRef}
                            aditionalColsAmount={aditionalColsAmount}
                            doubleClickAction={doubleClickAction}
                        />
                    ))
                }
            </tbody>
        </table>
    );
};

export default TableContainer;