import { validationDateParam } from '../utils/dateFormat'
import * as Yup from 'yup';

export const CreateValues = () => {
    return {
        initialValues: {
            // codigo: '',
            nombres: '',
            apellido_paterno: '',
            apellido_materno: '',
            email: '',
            ci: '',
            estado: '',
            // direccion: '',
            password: '',
            rols: [],
        },
        fieldsValidation: Yup.object().shape({
            // codigo: Yup.string()
            //     .min(4, 'Mínimo 4 caracteres!')
            //     .max(10, 'Máximo 10 caracteres!')
            //     .required('Campo requerido'),
            nombres: Yup.string()
                .min(3, 'Mínimo 4 caracteres!')
                .max(50, 'Máximo 40 caracteres!')
                .required('Campo requerido'),
            estado: Yup.string()
                .required('Campo requerido'),
            password: Yup.string()
                .min(4, 'Mínimo 4 caracteres!')
                .required('Campo requerido'),
        }),
        fields: [
            // {
            //     label: 'Código de empleado',
            //     name: 'codigo',
            //     type: 'text',
            //     required: true,
            // },
            {
                label: 'Nombres',
                name: 'nombres',
                type: 'text',
                required: true,
                placeholder: 'Nombre...',
                autoFocus: true
            },
            {
                label: 'Apellido paterno',
                name: 'apellido_paterno',
                type: 'text',
                placeholder: 'Apellido paterno...',
                required: false
            },
            {
                label: 'Apellido materno',
                name: 'apellido_materno',
                type: 'text',
                placeholder: 'Apellido materno...',
                required: false
            },
            {
                label: 'Correo electrónico',
                name: 'email',
                type: 'email',
                placeholder: 'name@mail.com...',
                required: false
            },
            {
                label: 'Carnet',
                name: 'ci',
                type: 'text',
                placeholder: '123456lp...',
                required: false
            },
            {
                label: 'Estado',
                name: 'estado',
                type: 'select',
                required: true,
                options: [
                    {
                        label: 'Activo',
                        value: 'activo'
                    },
                    {
                        label: 'Inactivo',
                        value: 'inactivo'
                    },
                ]
            },
            // {
            //     label: 'Dirección',
            //     name: 'direccion',
            //     type: 'text',
            //     required: false
            // },
            {
                label: 'Contraseña',
                name: 'password',
                type: 'password',
                placeholder: '******',
                required: true
            },
            {
                label: 'Asignar roles',
                name: 'rols',
                type: 'checkboxes',
                required: false,
                urlApi: '/roles',
                indexLabel: 'etiqueta'
            },
        ]
    }
}

export const UpdateValues = (data) => {
    let rols = []
    data?.rols?.forEach(rol => {
        rols.push(rol.id + '')
    });

    return {
        initialValues: {
            id: data.id || '',
            codigo: data.codigo || '',
            nombres: data.nombres || '',
            apellido_paterno: data.apellido_paterno || '',
            apellido_materno: data.apellido_materno || '',
            email: data.email || '',
            ci: data.ci || '',
            estado: data.estado || '',
            // direccion: data.direccion || '',
            rols: rols,
            password: '',
            createdAt: validationDateParam(data?.createdAt) || '',
        },
        fieldsValidation: Yup.object().shape({
            // codigo: Yup.string()
            //     .min(4, 'Mínimo 4 caracteres!')
            //     .max(10, 'Máximo 10 caracteres!')
            //     .required('Campo requerido'),
            nombres: Yup.string()
                .min(3, 'Mínimo 4 caracteres!')
                .max(50, 'Máximo 40 caracteres!')
                .required('Campo requerido'),
            estado: Yup.string()
                .required('Campo requerido'),
            password: Yup.string()
                .min(4, 'Mínimo 4 caracteres!')
            // .required('Campo requerido'),
        }),
        fields: [
            {
                label: 'Código de empleado',
                name: 'codigo',
                type: 'text',
                required: false,
                disabled: true
            },
            {
                label: 'Nombres',
                name: 'nombres',
                type: 'text',
                required: true,
                placeholder: 'Nombre...',
                autoFocus: true
            },
            {
                label: 'Apellido paterno',
                name: 'apellido_paterno',
                type: 'text',
                placeholder: 'Apellido paterno...',
                required: false
            },
            {
                label: 'Apellido materno',
                name: 'apellido_materno',
                type: 'text',
                placeholder: 'Apellido materno...',
                required: false
            },
            {
                label: 'Correo electrónico',
                name: 'email',
                type: 'email',
                placeholder: 'name@mail.com...',
                required: false
            },
            {
                label: 'Carnet',
                name: 'ci',
                type: 'text',
                placeholder: '123456lp...',
                required: false
            },
            {
                label: 'Estado',
                name: 'estado',
                type: 'select',
                required: false,
                options: [
                    {
                        label: 'Activo',
                        value: 'activo'
                    },
                    {
                        label: 'Inactivo',
                        value: 'inactivo'
                    },
                ]
            },
            // {
            //     label: 'Dirección',
            //     name: 'direccion',
            //     type: 'text',
            //     required: false
            // },
            {
                label: 'Fecha de creación',
                name: 'createdAt',
                type: 'date',
                required: false,
                disabled: true
            },
            {
                label: 'Contraseña',
                name: 'password',
                type: 'password',
                required: false
            },
            {
                label: 'Asignar roles',
                name: 'rols',
                type: 'checkboxes',
                required: false,
                urlApi: '/roles',
                indexLabel: 'etiqueta'
            }
        ]
    }
}

export const CreateClientValues = () => {
    return {
        initialValues: {
            nombres: 'demo2',
            apellido_paterno: '',
            apellido_materno: '',
            telefono: '',
            zona: '',
            tiempo_de_consumo: '',
            tipo_negocio: '',
            password: '1234',
            ci: '',
            direccion: '',
            foto_negocio: '',
            telefono2: '',
            telefono3: '',
            geolocalizacion: '',
            distrito: 'D3',
            estado: 'inactivo',
            vendedores: [],
        },
        fieldsValidation: Yup.object().shape({
            // codigo: Yup.string()
            //     .min(4, 'Mínimo 4 caracteres!')
            //     .max(10, 'Máximo 10 caracteres!')
            //     .required('Campo requerido'),
            nombres: Yup.string()
                .min(3, 'Mínimo 4 caracteres!')
                .max(50, 'Máximo 40 caracteres!')
                .required('Campo requerido'),
            estado: Yup.string()
                .required('Campo requerido'),
            password: Yup.string()
                .min(4, 'Mínimo 4 caracteres!')
                .required('Campo requerido'),
        }),
        fields: [
            {
                label: 'Nombres',
                name: 'nombres',
                type: 'text',
                required: true,
                placeholder: 'Nombre...',
                autoFocus: true
            },
            {
                label: 'Apellido paterno',
                name: 'apellido_paterno',
                type: 'text',
                placeholder: 'Apellido paterno...',
                required: false
            },
            {
                label: 'Apellido materno',
                name: 'apellido_materno',
                type: 'text',
                placeholder: 'Apellido materno...',
                required: false
            },
            {
                label: 'Telefono',
                name: 'telefono',
                type: 'text',
                placeholder: '12345678',
                required: false
            },
            {
                label: 'Tipo de negocio',
                name: 'tipo_negocio',
                type: 'select',
                required: false,
                options: [
                    {
                        value: 'Abarrotes',
                        label: 'Abarrotes'
                    },
                    {
                        value: 'Restaurante',
                        label: 'Restaurante'
                    },
                    {
                        value: 'Carniceria',
                        label: 'Carniceria'
                    },
                    {
                        value: 'Tienda',
                        label: 'Tienda'
                    },
                    {
                        value: 'Puesto',
                        label: 'Puesto'
                    },
                    {
                        value: 'Kiosco',
                        label: 'Kiosco'
                    },
                    {
                        value: 'Feria',
                        label: 'Feria'
                    },
                    {
                        value: 'Empresa',
                        label: 'Empresa'
                    },
                    {
                        value: 'Mayorista',
                        label: 'Mayorista'
                    },
                    {
                        value: 'Fabrica',
                        label: 'Fabrica'
                    },
                ],
                default: true
            },
            {
                label: 'Zona',
                name: 'zona',
                type: 'text',
                placeholder: '##',
                required: false
            },
            {
                type: 'divider',
            },
            {
                label: 'Contraseña',
                name: 'password',
                type: 'password',
                required: false,
                placeholder: '******',
            },
            {
                label: 'Tiempo de consumo por turril',
                name: 'tiempo_de_consumo',
                type: 'text',
                placeholder: 'Tiempo de consumo',
                required: false
            },
            {
                label: 'Carnet',
                name: 'ci',
                type: 'text',
                placeholder: '123456lp...',
                required: false
            },
            {
                label: 'Dirección',
                name: 'direccion',
                type: 'text',
                placeholder: '#',
                required: false
            },
            // {
            //     label: 'Foto negocio',
            //     name: 'foto_negocio',
            //     type: 'text',
            //     placeholder: '##',
            //     required: false
            // },
            {
                label: 'Telefono 2',
                name: 'telefono2',
                type: 'text',
                placeholder: '12345678',
                required: false
            },
            {
                label: 'Telefono 3',
                name: 'telefono3',
                type: 'text',
                placeholder: '12345678',
                required: false
            },
            {
                label: 'Geolocalización',
                name: 'geolocalizacion',
                type: 'text',
                placeholder: '##',
                required: false
            },
            {
                label: 'Distrito',
                name: 'distrito',
                type: 'selectSearch',
                urlApi: `/nombreEstado/Distritos/subEstados`,
                required: false,
                labelDescription: 'nombre',
                labelId: 'nombre',
                placeholder: 'Distrito...',
                autoFocus: false,
                defaultValue: { value: 'D3', label: 'D3' }
            },
            {
                label: 'Estado',
                name: 'estado',
                type: 'select',
                required: true,
                options: [
                    {
                        label: 'Activo',
                        value: 'activo'
                    },
                    {
                        label: 'Inactivo',
                        value: 'inactivo'
                    },
                ]
            },
            {
                label: 'Vendedores encargados',
                name: 'vendedores',
                type: 'checkboxes',
                required: false,
                urlApi: '/vendedores',
                indexLabel: 'nombre'
            },
        ]
    }
}

export const UpdateClientValues = (prevData) => {
    let vendedores = []
    prevData?.vendedores?.forEach(u => {
        vendedores.push(u.id + '')
    });
    return {
        initialValues: {
            user_id: prevData.id ?? null,
            nombres: prevData.nombres ?? '',
            apellido_paterno: prevData.apellido_paterno ?? '',
            apellido_materno: prevData.apellido_materno ?? '',
            telefono: prevData.telefono ?? '',
            zona: prevData.zona ?? '',
            ci: prevData.ci ?? '',
            tiempo_de_consumo: prevData.tiempo_de_consumo ?? '',
            tipo_negocio: prevData.tipo_negocio ?? '',
            telefono2: prevData.telefono2 ?? '',
            telefono3: prevData.telefono3 ?? '',
            estado: prevData.estado ?? 'inactivo',
            geolocalizacion: prevData.geolocalizacion ?? '',
            geocurrent: prevData.geocurrent ?? '',
            obs: prevData.obs ?? '',
            avatarImg: prevData.avatarImg ?? '',
            password: '',
            distrito: prevData.distrito ?? '',
            vendedores: vendedores ?? [],
        },
        fieldsValidation: Yup.object().shape({
            // codigo: Yup.string()
            //     .min(4, 'Mínimo 4 caracteres!')
            //     .max(10, 'Máximo 10 caracteres!')
            //     .required('Campo requerido'),
            nombres: Yup.string()
                .min(3, 'Mínimo 4 caracteres!')
                .max(50, 'Máximo 40 caracteres!')
                .required('Campo requerido'),
            estado: Yup.string()
                .required('Campo requerido'),
            // password: Yup.string()
            //     .min(4, 'Mínimo 4 caracteres!')
            //     .required('Campo requerido'),
        }),
        fields: [
            {
                label: 'Nombres',
                name: 'nombres',
                type: 'text',
                required: true,
                placeholder: 'Nombre...',
                autoFocus: true
            },
            {
                label: 'Apellido paterno',
                name: 'apellido_paterno',
                type: 'text',
                placeholder: 'Apellido paterno...',
                required: false
            },
            {
                label: 'Apellido materno',
                name: 'apellido_materno',
                type: 'text',
                placeholder: 'Apellido materno...',
                required: false
            },
            {
                label: 'Telefono',
                name: 'telefono',
                type: 'text',
                placeholder: '12345678',
                required: false
            },
            {
                label: 'Tipo de negocio',
                name: 'tipo_negocio',
                type: 'select',
                required: false,
                options: [
                    {
                        value: 'Abarrotes',
                        label: 'Abarrotes'
                    },
                    {
                        value: 'Restaurante',
                        label: 'Restaurante'
                    },
                    {
                        value: 'Carniceria',
                        label: 'Carniceria'
                    },
                    {
                        value: 'Tienda',
                        label: 'Tienda'
                    },
                    {
                        value: 'Puesto',
                        label: 'Puesto'
                    },
                    {
                        value: 'Kiosco',
                        label: 'Kiosco'
                    },
                    {
                        value: 'Feria',
                        label: 'Feria'
                    },
                    {
                        value: 'Empresa',
                        label: 'Empresa'
                    },
                    {
                        value: 'Mayorista',
                        label: 'Mayorista'
                    },
                    {
                        value: 'Fabrica',
                        label: 'Fabrica'
                    },
                ],
                default: true
            },
            {
                label: 'Zona',
                name: 'zona',
                type: 'text',
                placeholder: '##',
                required: false
            },
            {
                type: 'divider',
            },
            {
                label: 'Contraseña',
                name: 'password',
                type: 'password',
                required: false,
                placeholder: '******',
            },
            {
                label: 'Tiempo de consumo por turril',
                name: 'tiempo_de_consumo',
                type: 'text',
                placeholder: 'Tiempo de consumo',
                required: false
            },
            {
                label: 'Carnet',
                name: 'ci',
                type: 'text',
                placeholder: '123456lp...',
                required: false
            },
            {
                label: 'Dirección',
                name: 'direccion',
                type: 'text',
                placeholder: '#',
                required: false
            },
            // {
            //     label: 'Foto negocio',
            //     name: 'foto_negocio',
            //     type: 'text',
            //     placeholder: '##',
            //     required: false
            // },
            {
                label: 'Telefono 2',
                name: 'telefono2',
                type: 'text',
                placeholder: '12345678',
                required: false
            },
            {
                label: 'Telefono 3',
                name: 'telefono3',
                type: 'text',
                placeholder: '12345678',
                required: false
            },
            {
                label: 'Geolocalización',
                name: 'geolocalizacion',
                type: 'text',
                placeholder: '##',
                required: false
            },
            {
                label: 'Distrito',
                name: 'distrito',
                type: 'selectSearch',
                urlApi: `/nombreEstado/Distritos/subEstados`,
                required: false,
                labelDescription: 'nombre',
                labelId: 'nombre',
                placeholder: 'Distrito...',
                autoFocus: false,
                defaultValue: prevData.distrito && { value: prevData.distrito, label: prevData.distrito }
            },
            {
                label: 'Estado',
                name: 'estado',
                type: 'select',
                required: true,
                options: [
                    {
                        label: 'Activo',
                        value: 'activo'
                    },
                    {
                        label: 'Inactivo',
                        value: 'inactivo'
                    },
                ]
            },
            {
                label: 'Vendedores encargados',
                name: 'vendedores',
                type: 'checkboxes',
                required: false,
                urlApi: '/vendedores',
                indexLabel: 'nombre'
            },
        ]
    }
}